import { Routes, Route, Navigate } from 'react-router-dom'

import { useCurrentUser } from '@contexts/currentUser'
import UnitDetails from '@pages/UnitDetails'
import EducatorProjectSubmission from '@pages/EducatorProjectSubmission'

import Projects from './pages/Projects'
import SeriesDetails from './pages/SeriesDetails'
import EducatorProjectInsights from './pages/EducatorProjectInsights/EducatorProjectInsights'
import TutorInsights from './pages/TutorInsights/TutorInsights'
import TutorAssignmentList from './pages/TutorAssignmentList'
import TutorAssignmentDetails from './pages/TutorAssignmentDetails'
import Workshops from './pages/Workshops'
import Settings from './pages/Settings'
import Archived from './pages/Archived'

const Student = () => {
  const { user: { role } } = useCurrentUser()

  if (role !== 'student') {
    return <Navigate to='/' />
  }

  return (
    <Routes>
      <Route element={<Archived />}>
        <Route index element={<Navigate to='/students/tutors' />} />
        <Route path='/projects' element={<Navigate to='/students/courses' />} />
        <Route path='/units/:slug/*' element={<UnitDetails pathPrefix='students' />} />
        <Route path='/courses/:id' element={<SeriesDetails />} />
        <Route path='/courses' element={<Projects />} />
        <Route path='/course_insights/:id' element={<SeriesDetails pageHeading='Course Insights' projectPath='project_insights' />} />
        <Route path='/course_insights' element={<Projects pageHeading='Course Insights' />} />
        <Route path='/project_insights/:id' element={<EducatorProjectInsights />} />
        <Route path='/projects/:id/*' element={<EducatorProjectSubmission />} />
        <Route path='/tutors' element={<TutorAssignmentList pageHeading='Tutors' />} />
        <Route path='/tutors/assignments/:id' element={<TutorAssignmentDetails />} />
        <Route path='/tutor_insights' element={<TutorAssignmentList pageHeading='Insights' closed />} />
        <Route path='/tutor_insights/assignments/:id' element={<TutorInsights />} />
        <Route path='/settings' element={<Settings />} />
      </Route>
    </Routes>
  )
}

export default Student
