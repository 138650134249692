import { useState, useRef } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { FormProvider, useForm } from 'react-hook-form'

import BreadCrumbs from '@components/Breadcrumbs'
import Tab from '@components/Tab'
import { useBreakpoint } from '@contexts/breakpoints'

import { useEducatorProjectSubmission } from './hooks'
import ChatPlayground from './ChatPlayground'
import CompletionPlayground from './CompletionPlayground'
import Skeleton from './Skeleton'
import SubmitModal from './SubmitModal'
import ProjectBlocks from './ProjectBlocks'
import CodingPlayground from './CodingPlayground'
import DocumentPlayground from './DocumentPlayground'

const EducatorProjectSubmission = () => {
  const location = useLocation()
  const submitDisabled = location.state?.submitDisabled || false

  const contentPanelRef = useRef(null)
  const isMobile = useBreakpoint('sm')
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false)
  const [currentBlockIndex, setCurrentBlockIndex] = useState(0)
  const { id: educatorProjectId } = useParams()
  const { project, submission, isLoading, updateSubmission } = useEducatorProjectSubmission({ educatorProjectId })
  const methods = useForm({ mode: 'onTouched', defaultValues: { message: null, attachments: [] } })

  const currentBlock = project?.blocks[currentBlockIndex]
  const currentBlockSubmission = submission?.blockSubmissions?.find(blockSubmission => blockSubmission.blockId === currentBlock?.blockId)
  const projectName = project?.name || 'Untitled project'
  const seriesName = project?.series?.name || 'Untitled series'

  if (isLoading || !project || !submission) return <Skeleton />

  const moveToNextBlock = () => {
    setCurrentBlockIndex(currentBlockIndex + 1)

    contentPanelRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const moveToPreviousBlock = () => {
    setCurrentBlockIndex(currentBlockIndex - 1)

    contentPanelRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <BreadCrumbs>
        <BreadCrumbs.Link label='My courses' to='/students/courses' />
        <BreadCrumbs.Link label={seriesName} to={`/students/courses/${project.series.id}`} />
        <BreadCrumbs.Text label={projectName} />
      </BreadCrumbs>

      <Choose>
        <When condition={isMobile}>
          <Tab.Group className='h-full'>
            <Tab.List className='sticky top-0'>
              <Tab>
                Lesson
              </Tab>

              <Tab>
                Chat
              </Tab>
            </Tab.List>

            <Tab.Panels className='h-[calc(100%-51px)]'>
              <Tab.Panel className='h-full'>
                <div ref={contentPanelRef} className='py-3 px-5 flex flex-col'>
                  <ProjectBlocks
                    blocks={project.blocks}
                    blockSubmissions={submission.blockSubmissions}
                    submissionId={submission.id}
                    submissionStatus={submission.status}
                    currentBlock={currentBlock}
                    currentBlockSubmission={currentBlockSubmission}
                    currentBlockIndex={currentBlockIndex}
                    moveToNextBlock={moveToNextBlock}
                    moveToPreviousBlock={moveToPreviousBlock}
                    submitDisabled={submitDisabled}
                    setIsSubmitModalOpen={setIsSubmitModalOpen}
                  />
                </div>
              </Tab.Panel>

              <Tab.Panel className='h-full w-full bg-white'>
                <Choose>
                  <When condition={project.playground === 'CHAT'}>
                    <FormProvider {...methods}>
                      <ChatPlayground submissionId={submission.id} />
                    </FormProvider>
                  </When>

                  <When condition={project.playground === 'COMPLETION'}>
                    <CompletionPlayground submissionId={submission.id} />
                  </When>

                  <When condition={project.playground === 'CODE'}>
                    <CodingPlayground submissionId={submission.id} />
                  </When>

                  <When condition={project.playground === 'DOC'}>
                    <DocumentPlayground submissionId={submission.id} />
                  </When>
                </Choose>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </When>

        <Otherwise>
          <PanelGroup className='flex grow bg-white' style={{ overflow: 'auto' }} direction='horizontal' autoSaveId='studentProjectPlayground'>
            <Panel
              style={{ overflow: 'auto' }}
              className='flex flex-col pl-5 pr-3 bg-gray-50'
              defaultSize={50}
              minSize={30}
            >
              <div className='py-3' ref={contentPanelRef}>
                <ProjectBlocks
                  blocks={project.blocks}
                  blockSubmissions={submission.blockSubmissions}
                  submissionId={submission.id}
                  submissionStatus={submission.status}
                  currentBlock={currentBlock}
                  currentBlockSubmission={currentBlockSubmission}
                  currentBlockIndex={currentBlockIndex}
                  moveToNextBlock={moveToNextBlock}
                  moveToPreviousBlock={moveToPreviousBlock}
                  submitDisabled={submitDisabled}
                  setIsSubmitModalOpen={setIsSubmitModalOpen}
                />
              </div>
            </Panel>

            <PanelResizeHandle className='flex items-center justify-center h-full'>
              <div className='bg-gray-50 px-1 h-full' />
              <div className='w-[1px] h-full bg-gray-200' />
              <div className='bg-white px-1 h-full' />
            </PanelResizeHandle>

            <Panel
              className='relative overflow-auto'
              defaultSize={50}
              minSize={30}
            >
              <Choose>
                <When condition={project.playground === 'CHAT'}>
                  <FormProvider {...methods}>
                    <ChatPlayground submissionId={submission.id} />
                  </FormProvider>
                </When>

                <When condition={project.playground === 'COMPLETION'}>
                  <CompletionPlayground submissionId={submission.id} />
                </When>

                <When condition={project.playground === 'CODE'}>
                  <CodingPlayground submissionId={submission.id} />
                </When>

                <When condition={project.playground === 'DOC'}>
                  <DocumentPlayground submissionId={submission.id} />
                </When>
              </Choose>
            </Panel>
          </PanelGroup>
        </Otherwise>
      </Choose>

      <SubmitModal
        seriesId={project?.series?.id}
        educatorProjectId={educatorProjectId}
        closeModal={() => setIsSubmitModalOpen(false)}
        isOpen={isSubmitModalOpen}
      />
    </>
  )
}

export default EducatorProjectSubmission
