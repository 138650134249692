import { useState } from 'react'
import ReactPlayer from 'react-player'

import Button from '@components/Button'
import LinkButton from '@components/LinkButton'
import Notification from '@components/Notification'
import Tooltip from '@components/Tooltip'

import { useUpdateBlockSubmission } from './hooks'

const VideoBlock = ({
  url,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  submitDisabled,
  openSubmitModal,
  submissionStatus,
  submissionId,
  blockId
}) => {
  const [videoErrored, setVideoErrored] = useState(false)

  const { updateBlockSubmission } = useUpdateBlockSubmission({ educatorProjectSubmissionId: submissionId })

  const next = () => {
    updateBlockSubmission({ blockId, status: 'COMPLETED' })
    moveToNextBlock()
  }

  const submitProject = () => {
    updateBlockSubmission({ blockId, status: 'COMPLETED' })
    openSubmitModal()
  }

  return (
    <div className='mt-3'>
      <Choose>
        <When condition={videoErrored}>
          <Notification className='w-fit' theme='error'>Oops! Something went wrong while loading your video. </Notification>
        </When>

        <When condition={url}>
          <ReactPlayer
            width='100%'
            controls
            url={url}
            onError={() => setVideoErrored(true)}
          />
        </When>
      </Choose>

      <div className='flex flex-row items-center justify-between mt-3'>
        <LinkButton
          disabled={isFirstBlock}
          onClick={moveToPreviousBlock}
        >
          back
        </LinkButton>

        <Choose>
          <When condition={isLastBlock}>
            <Button
              id='submit-button'
              className='self-end'
              disabled={submissionStatus === 'COMPLETED' || submitDisabled}
              label={submissionStatus === 'COMPLETED' ? 'Submitted' : 'Submit'}
              onClick={submitProject}
            />

            <If condition={submitDisabled}>
              <Tooltip anchorSelect='#submit-button' delayShow={200}>
                Submissions are closed for this course
              </Tooltip>
            </If>
          </When>

          <Otherwise>
            <Button
              className='self-end'
              label='Next'
              onClick={next}
            />
          </Otherwise>
        </Choose>
      </div>
    </div>
  )
}

export default VideoBlock
