import { useState } from 'react'

import {
  Cog6ToothIcon,
  EyeIcon,
  PencilSquareIcon,
  ArrowLeftIcon
} from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import Tab from '@components/Tab'

import Settings from './Settings'
import Content from './Content'
import Preview from './Preview'

const Overview = ({
  id,
  name,
  readme,
  blocks,
  status,
  playground,
  classrooms,
  document,
  documentText,
  mode,
  prompt,
  socraticModeEnabled,
  stemModeEnabled,
  processing,
  submission,
  series,
  refetch
}) => {
  const [selectedTab, setSelectedTab] = useState(0)

  if (status === 'loading') return

  return (
    <>
      <Tab.Group className='h-[calc(100%-45px-51px)]' selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List>
          <Tab>
            <PencilSquareIcon className='h-6 w-6 mr-1' />
            <span className='md:block hidden'>Content</span>
          </Tab>

          <Tab>
            <Cog6ToothIcon className='h-6 w-6 mr-1' />
            <span className='md:block hidden'>Settings</span>
          </Tab>

          <Tab>
            <EyeIcon className='h-6 w-6 mr-1' />
            <span className='md:block hidden'>Preview</span>
          </Tab>

          <Link
            to={`/educators/courses/${series.id}`}
            className='ml-auto flex items-center px-5 text-blue-500'
          >
            <ArrowLeftIcon className='h-5 w-5 mr-2' />
            Back to course
          </Link>
        </Tab.List>

        <Tab.Panels className='flex overflow-scroll h-full'>
          <Tab.Panel className='w-full'>
            <Content
              id={id}
              readme={readme}
              blocks={blocks}
              refetch={refetch}
            />
          </Tab.Panel>

          <Tab.Panel className='w-full md:mx-5'>
            <Settings
              id={id}
              name={name}
              readme={readme}
              playground={playground}
              classrooms={classrooms}
              document={document}
              documentText={documentText}
              mode={mode}
              prompt={prompt}
              socraticModeEnabled={socraticModeEnabled}
              stemModeEnabled={stemModeEnabled}
              processing={processing}
              submission={submission}
            />
          </Tab.Panel>

          <Tab.Panel className='flex grow overflow-auto'>
            <Preview id={id} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </>
  )
}

export default Overview
