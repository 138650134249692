import Button from '@components/Button'
import LinkButton from '@components/LinkButton'
import Tooltip from '@components/Tooltip'

import { useUpdateBlockSubmission } from './hooks'

const ImageBlock = ({
  url,
  caption,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  submitDisabled,
  openSubmitModal,
  submissionStatus,
  submissionId,
  blockId
}) => {
  const { updateBlockSubmission } = useUpdateBlockSubmission({ educatorProjectSubmissionId: submissionId })

  const next = () => {
    updateBlockSubmission({ blockId, status: 'COMPLETED' })
    moveToNextBlock()
  }

  const submitProject = () => {
    updateBlockSubmission({ blockId, status: 'COMPLETED' })
    openSubmitModal()
  }

  return (
    <div className='mt-3'>
      <img src={url} className='w-full rounded-lg' />

      <p className='mt-3'>{caption}</p>

      <div className='flex flex-row items-center justify-between mt-3'>
        <LinkButton
          disabled={isFirstBlock}
          onClick={moveToPreviousBlock}
        >
          back
        </LinkButton>

        <Choose>
          <When condition={isLastBlock}>
            <Button
              id='submit-button'
              className='self-end'
              disabled={submissionStatus === 'COMPLETED' || submitDisabled}
              label={submissionStatus === 'COMPLETED' ? 'Submitted' : 'Submit'}
              onClick={submitProject}
            />

            <If condition={submitDisabled}>
              <Tooltip anchorSelect='#submit-button' delayShow={200}>
                Submissions are now closed for this course
              </Tooltip>
            </If>
          </When>

          <Otherwise>
            <Button
              className='self-end'
              label='Next'
              onClick={next}
            />
          </Otherwise>
        </Choose>
      </div>
    </div>
  )
}

export default ImageBlock
