import { useMutation, useQueryClient } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'

import { request } from '@helpers/graphql'
import TextEditor from '@components/TextEditor'
import Button from '@components/Button'
import LinkButton from '@components/LinkButton'
import SecureMarkdown from '@components/SecureMarkdown'
import Tooltip from '@components/Tooltip'

const CREATE_FREE_RESPONSE_SUBMISSION_MUTATION = gql`
  mutation CreateFreeResponseSubmission($input: CreateFreeResponseSubmissionInput!) {
    createFreeResponseSubmission(input: $input) {
      blockSubmission {
        content {
          ... on FreeResponseSubmission {
            answer
          }
        }
      }

      errors {
        message
      }

    }
  }
`

const FreeResponseBlock = ({
  blockId,
  submissionId,
  question,
  moveToNextBlock,
  moveToPreviousBlock,
  isFirstBlock,
  isLastBlock,
  openSubmitModal,
  submitDisabled,
  submissionStatus,
  answer
}) => {
  const queryClient = useQueryClient()

  const { mutate: createFreeResponseSubmission } = useMutation({
    mutationFn: async variables => request(CREATE_FREE_RESPONSE_SUBMISSION_MUTATION, { input: { blockId, educatorProjectSubmissionId: submissionId, ...variables } }),
    onSuccess: () => { queryClient.invalidateQueries('educatorProjectSubmission') }
  })

  const { handleSubmit, formState: { errors }, control } = useForm({
    mode: 'onTouched',
    defaultValues: { answer }
  })

  const submit = data => {
    if (submissionStatus === 'COMPLETED') {
      moveToNextBlock()
    } else if (isLastBlock) {
      createFreeResponseSubmission(data)
      openSubmitModal()
    } else {
      createFreeResponseSubmission(data)
      moveToNextBlock()
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(submit)}
        className='flex flex-col mt-5'
      >
        <SecureMarkdown content={question} />

        <Controller
          name='answer'
          rules={{
            required: 'Answer the question to move to the next section'
          }}
          control={control}
          render={({ field }) => (
            <TextEditor
              disabled={submissionStatus === 'COMPLETED'}
              className='min-h-[200px]'
              {...field}
            />
          )}
        />

        <If condition={errors.answer}>
          <p className='text-sm mt-1 text-red-500 font-semibold' role='alert'>{errors.answer.message}</p>
        </If>

        <div className='flex flex-row items-center justify-between mt-3'>
          <LinkButton
            type='button'
            disabled={isFirstBlock}
            onClick={moveToPreviousBlock}
          >
            back
          </LinkButton>

          <Choose>
            <When condition={isLastBlock}>
              <Button
                type='submit'
                id='submit-button'
                className='self-end'
                disabled={submissionStatus === 'COMPLETED' || submitDisabled}
                label={submissionStatus === 'COMPLETED' ? 'Submitted' : 'Submit'}
              />

              <If condition={submitDisabled}>
                <Tooltip anchorSelect='#submit-button' delayShow={200}>
                  Submissions are now closed for this course
                </Tooltip>
              </If>
            </When>

            <Otherwise>
              <Button
                type='submit'
                className='self-end'
                label='Next'
              />
            </Otherwise>
          </Choose>
        </div>
      </form>
    </>
  )
}

export default FreeResponseBlock
