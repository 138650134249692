import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import TextEditor from '@components/TextEditor'
import Label from '@components/Label'
const UPDATE_TEXT_BLOCK_MUTATION = gql`
  mutation updateTextBlock($input: UpdateTextBlockInput!) {
    updateTextBlock(input: $input) {
      text {
        content
      }
      errors {
        message
      }
    }
  }
`

const TextForm = ({ id, content, refetch }) => {
  const { control, handleSubmit } = useForm({ defaultValues: { content } })
  const { mutate: updateTextBlock, isLoading } = useMutation({
    mutationFn: async variables => request(UPDATE_TEXT_BLOCK_MUTATION, { input: { id, ...variables } }),
    onSuccess: () => refetch()
  })

  const onSubmit = ({ content }) => {
    updateTextBlock({ content })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full flex flex-col px-5 py-5'>
      <Label labelFor='content' title='Content' />
      <Controller
        name='content'
        control={control}
        render={({ field }) => (
          <TextEditor
            id='content'
            imagesEnabled
            className='min-h-[200px]'
            {...field}
          />
        )}
      />

      <Button
        className='mt-5 ml-auto'
        type='submit'
        disabled={isLoading}
        label={isLoading ? 'Saving...' : 'Save'}
      />
    </form>
  )
}

export default TextForm
