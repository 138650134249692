import { useState } from 'react'
import { gql } from 'graphql-request'
import { useQuery, useMutation } from '@tanstack/react-query'
import {
  ChevronLeftIcon,
  ChatBubbleLeftEllipsisIcon,
  XMarkIcon,
  LightBulbIcon
} from '@heroicons/react/24/outline'
import { Link, Routes, Route, Navigate, useParams, useNavigate } from 'react-router-dom'

import { request } from '@helpers/graphql'
import { formatTimestamp } from '@helpers/format'
import Spinner from '@components/Spinner'
import Card from '@components/Card'
import CopyToClipboardInput from '@components/CopyToClipboardInput'
import Button from '@components/Button'
import VerticalTabNav from '@components/VerticalTabNav'

import Insights from './Insights'
import StudentActivity from './StudentActivity'

const ORGANIZATION_QUERY = gql`
  query organization {
    organization {
      privateStudentChatsEnabled
    }
  }
`

const ASSIGNMENT_QUERY = gql`
  query assignment($id: ID!, $page: Int!, $privateChatsEnabled: Boolean!) {
    node(id: $id) {
      ... on TutorAssignment {
        id
        classroom {
          id
          name
        }
        closed
        createdAt
        closedAt
        summaryStatistics {
          positiveSentimentCount
          neutralSentimentCount
          negativeSentimentCount
          curiousLearningStatusCount
          neutralLearningStatusCount
          confusedLearningStatusCount
          totalChatCount
          totalEngagementCount
        }
        chats(page: $page, perPage: 50) @skip(if: $privateChatsEnabled) {
          pagesCount
          nodesCount
          nodes {
            id
            createdAt
            profile {
              fullName
            }
            learningStatus
            sentiment
            flaggedByModeration
            messages {
              nodesCount
            }
          }
        }
      }
    }
  }
`

const UPDATE_ASSIGNMENT_MUTATION = gql`
  mutation updateTutorAssignment($input: UpdateTutorAssignmentInput!) {
    updateTutorAssignment(input: $input) {
      assignment {
        id
      }
    }
  }
`

const SessionDetails = ({ tutorId, features }) => {
  const { id: assignmentId } = useParams()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const insightsEnabled = features.includes('tutor_insights')

  const { data: { organization = {} } = {}, isFetched } = useQuery({
    queryKey: ['organization'],
    queryFn: async () => request(ORGANIZATION_QUERY)
  })

  const { isLoading, data, refetch } = useQuery({
    queryKey: ['assignment', assignmentId, page],
    queryFn: async () => request(ASSIGNMENT_QUERY, { id: assignmentId, page, privateChatsEnabled: organization.privateStudentChatsEnabled }),
    refetchInterval: 120000, // Refetch every 2 minutes
    enabled: isFetched
  })

  const { mutate: update, isLoading: isUpdating } = useMutation({
    mutationFn: async data => request(UPDATE_ASSIGNMENT_MUTATION, { input: { assignmentId, ...data } }),
    onSuccess: () => {
      refetch()
      navigate('insights')
    }
  })

  const endSession = () => {
    update({ closed: true })
  }

  const assignment = data?.node || {}
  const chats = assignment?.chats?.nodes || []
  const classroom = assignment?.classroom || {}
  const summaryStatistics = assignment?.summaryStatistics || {}

  if (isLoading || !isFetched) {
    return <Spinner className='flex items-center justify-center w-full mt-32' />
  }

  return (
    <div className='md:px-5 py-3'>
      <Link
        to={`/educators/tutors/${tutorId}/sessions`}
        className='block text-blue-500 mb-3'
      >
        <ChevronLeftIcon className='w-4 h-4 inline-flex mr-1' />
        back
      </Link>

      <Card className='flex justify-between p-5'>
        <div>
          <div className='flex'>
            <h2 className='text-xl font-semibold mr-2'>
              {classroom.name}
            </h2>

            <Choose>
              <When condition={assignment.closed}>
                <span className='bg-gray-100 text-gray-900 px-2 py-1 rounded-full w-fit h-fit text-sm'>
                  Closed
                </span>
              </When>

              <Otherwise>
                <span className='bg-green-100 text-green-900 px-2 py-1 rounded-full w-fit h-fit text-sm'>
                  Active
                </span>
              </Otherwise>
            </Choose>
          </div>

          <p className='text-gray-700 text-sm mt-1'>Started {formatTimestamp(assignment.createdAt)}</p>
        </div>

        <If condition={!assignment.closed}>
          <CopyToClipboardInput
            className='w-1/4'
            value={`${window.location.origin}/students/tutors/assignments/${assignmentId}`}
            label='Student session link'
          />

          <Button
            disabled={isUpdating}
            theme='error'
            variant='outlined'
            label={isUpdating ? 'Updating...' : <span className='flex items-center gap-2'>End session<XMarkIcon className='size-6' /></span>}
            onClick={endSession}
          />
        </If>
      </Card>

      <div className='w-full flex'>
        <div className='flex justify-between items-center pt-5 pr-3'>
          <VerticalTabNav className='min-w-[200px]'>
            <VerticalTabNav.Link
              to='student_activity'
              label='Student activity'
              path='student_activity'
              icon={<ChatBubbleLeftEllipsisIcon className='h-6 w-6 mr-1' />}
            />

            <If condition={insightsEnabled}>
              <VerticalTabNav.Link
                to='insights'
                label={
                  <>
                    Insights

                    <div className='inline ml-3 text-xs bg-transparent text-blue-600 border-blue-600 border rounded-full px-2 py-1 font-normal'>
                      Experimental
                    </div>
                  </>
                }
                icon={<LightBulbIcon className='h-6 w-6 mr-1' />}
              />
            </If>
          </VerticalTabNav>
        </div>

        <div className='w-full pt-5 pl-3'>
          <Routes>
            <Route index element={<Navigate to={assignment.closed && insightsEnabled ? 'insights' : 'student_activity'} />} />

            <Route
              path='student_activity'
              element={
                <StudentActivity
                  chats={chats}
                  chatsPagesCount={chats?.pagesCount || 0}
                  privateStudentChatsEnabled={organization.privateStudentChatsEnabled}
                  summaryStatistics={summaryStatistics}
                />
              }
            />

            <If condition={insightsEnabled}>
              <Route
                path='insights'
                element={
                  <Insights
                    assignmentId={assignmentId}
                    assignmentClosed={assignment.closed}
                    assignmentClosedAt={assignment.closedAt}
                    classroomId={classroom.id}
                  />
                }
              />
            </If>
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default SessionDetails
