import React from 'react'
import { twMerge } from 'tailwind-merge'

const VerticalTabNav = ({ className, children }) => (
  <div className={twMerge('flex flex-col text-base space-y-1 h-full min-w-[250px]', className)}>
    {React.Children.map(children, child => {
      if (!child) return null

      return React.cloneElement(child)
    })}
  </div>
)

export default VerticalTabNav
