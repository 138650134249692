import { NavLink } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

const Link = ({ to, state, end = false, label, icon }) => (
  <NavLink
    to={to}
    state={state}
    end={end}
    className={({ isActive }) => twMerge(
      'hover:text-blue-600 hover:bg-gray-200 group flex gap-x-3 rounded-md p-2 leading-6 outline-none',
      isActive && 'bg-gray-200 font-semibold'
    )}
  >
    {icon}
    <span className={twMerge(
      icon ? 'sm:block hidden' : '',
      'ml-1'
    )}>{label}</span>
  </NavLink>
)

export default Link
