import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { CheckIcon, ClockIcon } from '@heroicons/react/24/outline'

const WrapperComponent = ({ children, id, isNextProject, isInProgress, isCompleted, projectPath, isAssignmentClosed }) => (
  <Choose>
    <When condition={isInProgress || isCompleted || isNextProject}>
      <Link
        className={twMerge(
          'flex flex-row w-full p-3 sm:rounded-lg shadow bg-white hover:bg-gray-100 hover:cursor-pointer',
          isNextProject && !isAssignmentClosed ? 'border-2 border-green-600 hover:bg-green-100' : 'border-2 border-transparent'
        )}
        to={`/students/${projectPath || 'projects'}/${id}`}
        state={{ submitDisabled: isAssignmentClosed }}
      >
        {children}
      </Link>
    </When>

    <Otherwise>
      <div className='flex flex-row w-full p-3 sm:rounded-lg shadow bg-gray-100 hover:cursor-disabled'>
        {children}
      </div>
    </Otherwise>
  </Choose>
)

const Project = ({ id, name, playground, isCompleted, isInProgress, isNextProject, projectPath, isAssignmentClosed }) => {
  return (
    <WrapperComponent
      id={id}
      isCompleted={isCompleted}
      isInProgress={isInProgress}
      isNextProject={isNextProject}
      projectPath={projectPath}
      isAssignmentClosed={isAssignmentClosed}
    >

      <div className='w-full text-left'>
        <p className='text-lg font-semibold'>{name}</p>

        <Choose>
          <When condition={isInProgress}>
            <p className='text-sm text-gray-500 inline-block'>In progress</p>
            <ClockIcon className='h-4 w-4 inline-block ml-1' />
          </When>

          <When condition={isCompleted}>
            <p className='text-sm text-gray-500 inline-block'>Completed</p>
            <CheckIcon className='h-4 w-4 inline-block ml-1' />
          </When>

          <Otherwise>
            <p className='text-sm text-gray-500'>Not started</p>
          </Otherwise>
        </Choose>
      </div>
    </WrapperComponent>
  )
}

export default Project
