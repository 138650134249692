import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'

import { request } from '@helpers/graphql'
import TextInput from '@components/TextInput'
import Button from '@components/Button'
import Label from '@components/Label'
import TextEditor from '@components/TextEditor'

const UPDATE_FREE_RESPONSE_BLOCK_MUTATION = gql`
  mutation updateFreeResponseBlock($input: UpdateFreeResponseBlockInput!) {
    updateFreeResponseBlock(input: $input) {
      freeResponse {
        id
        question
        markAllocation
        markScheme
        workingsRequired
      }
      errors {
        message
      }
    }
  }
`

const FreeResponseQuestionForm = ({ id, question, markAllocation, markScheme, workingsRequired, refetch }) => {
  const { control, register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onTouched',
    defaultValues: {
      question,
      markAllocation,
      markScheme,
      workingsRequired
    }
  })

  const { mutate: updateFreeResponseBlock, isLoading } = useMutation({
    mutationFn: async variables => request(UPDATE_FREE_RESPONSE_BLOCK_MUTATION, { input: { id, ...variables } }),
    onSuccess: refetch
  })

  const onSubmit = data => {
    updateFreeResponseBlock(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full flex flex-col px-5 py-5 gap-5'>
      <div>
        <Label labelFor='question' title='Question' />
        <Controller
          name='question'
          control={control}
          render={({ field }) => (
            <TextEditor
              imagesEnabled
              id='question'
              {...field}
            />
          )}
        />

        <If condition={errors.question}>
          <p className='mt-1 text-sm font-semibold text-red-500' role='alert'>{errors.question.message}</p>
        </If>
      </div>

      <div>
        <TextInput
          id='mark-allocation'
          label='Mark allocation'
          type='number'
          className='block w-fit'
          inputClassName='w-[150px]'
          {...register('markAllocation', {
            valueAsNumber: true,
            required: 'Mark allocation is required',
            min: { value: 1, message: 'Mark allocation must be greater than 0' }
          })}
        />

        <small>The maximum mark allocation for this question</small>

        <If condition={errors.markAllocation}>
          <p className='mt-1 text-sm font-semibold text-red-500' role='alert'>{errors.markAllocation.message}</p>
        </If>
      </div>

      <div>
        <Label labelFor='mark-scheme' title='Mark scheme' />
        <Controller
          name='markScheme'
          control={control}
          rules={{ required: 'Mark scheme is required' }}
          render={({ field }) => (
            <TextEditor
              id='mark-scheme'
              {...field}
            />
          )}
        />
        <small>
          Provide a mark scheme that includes a model answer, how to award marks etc.
        </small>

        <If condition={errors.markScheme}>
          <p className='mt-1 text-sm font-semibold text-red-500' role='alert'>{errors.markScheme.message}</p>
        </If>
      </div>

      <Button
        className='ml-auto'
        type='submit'
        disabled={isLoading}
        label={isLoading ? 'Saving...' : 'Save'}
      />
    </form>
  )
}

export default FreeResponseQuestionForm
