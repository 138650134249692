import { Link } from 'react-router-dom'
import Card from '@components/Card'
import LinkButton from '@components/LinkButton'

const NoResults = ({ showAssignmentModal }) => (
  <Card className='w-auto flex flex-col items-center justify-center p-10 m-5'>
    <img src='/swingset.svg' alt='Student reading' className='w-[120px] self-center mb-5' />
    <h4 className='text-2xl font-semibold'>No student activity just yet</h4>
    <p>
      <LinkButton onClick={showAssignmentModal}>Assign this tutor</LinkButton>
      &nbsp;to get started.
    </p>
  </Card>
)

export default NoResults
