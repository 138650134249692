import { useMutation } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { useForm, Controller } from 'react-hook-form'

import { request } from '@helpers/graphql'
import Button from '@components/Button'
import TextEditor from '@components/TextEditor'
import Label from '@components/Label'

const UPDATE_REFLECTION_BLOCK_MUTATION = gql`
  mutation updateReflectionBlock($input: UpdateReflectionBlockInput!) {
    updateReflectionBlock(input: $input) {
      reflection {
        question
      }
      errors {
        message
      }
    }
  }
`

const ReflectionQuestionForm = ({ id, question, refetch }) => {
  const { handleSubmit, control } = useForm({ defaultValues: { question } })

  const { mutate: updateReflectionBlock, isLoading } = useMutation({
    mutationFn: async variables => request(UPDATE_REFLECTION_BLOCK_MUTATION, { input: { id, ...variables } }),
    onSuccess: () => refetch()
  })

  const onSubmit = ({ question }) => {
    updateReflectionBlock({ question })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full flex flex-col px-5 py-5'>
      <Label labelFor='question' title='Question' />
      <Controller
        name='question'
        control={control}
        render={({ field }) => (
          <TextEditor
            imagesEnabled
            id='question'
            {...field}
          />
        )}
      />

      <Button
        className='ml-auto mt-3'
        type='submit'
        disabled={isLoading}
        label={isLoading ? 'Saving...' : 'Save'}
      />
    </form>
  )
}

export default ReflectionQuestionForm
